import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DotSpinner from './components/DotSpinner';

const PrivateRoute = () => {
    const permissions = useSelector((state) => state.auth.permissions);
    const token = localStorage.getItem("token");
    const location = useLocation();
    const { pathname } = location;
    const permisoEncontrado = permissions.find(permiso => permiso.ruta === pathname);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const isDifferent = !permissions.some(permission =>
            permission.nombre === "" &&
            permission.ruta === "" &&
            permission.permiso === true &&
            permission.menu === true
        );

        if (isDifferent) {
            setLoading(false);
        }
    }, [permissions]);

    if (loading) {
        return <DotSpinner />;
    }

    if (!token) {
        return <Navigate to={"/"} />;
    }

    //if (token) {
    //    return <Navigate to={"/"} />;
    //}

    if (!permisoEncontrado || !permisoEncontrado.permiso) {
        return <Navigate to={'/denegado'} />;
    }

    return <Outlet />;
}

export default PrivateRoute;
