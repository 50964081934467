import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { updateAuth, updateRenovar } from "../../features/auth/authSlice";
import DotSpinner from "../../components/DotSpinner";
import fetchApi from "../../utils/fechtData";

import '../../css/VistasGenerales/Login.css';
import '../../css/VistasGenerales/General.css';
import logoImage from '../../assets/images/mega_largo.png';
import blackfriday from '../../assets/images/counicado.webp';
import Swal from "sweetalert2";

const theme = createTheme();

export default function Login() {
  const [nombre, setNombre] = useState("");
  const [contra, setContra] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const Swal = require('sweetalert2');
  const dispatch = useDispatch();

  const IngresarLogin = async (event) => {
    event.preventDefault();

    if (nombre === "") {
      console.log("No está ingresando su usuario");
      return;
    } else if (contra === "") {
      console.log("No está ingresando su contraseña");
      return;
    }
    setLoading(true);

    const ingreso = {
      "userName": nombre,
      "password": contra
    };

    const procesamiento = async (datos) => {
      setLoading(false);
      if (datos.token) {
        localStorage.setItem("token", datos.token);
        localStorage.setItem("expiracion", datos.expiracion);
        dispatch(updateRenovar({
          token: datos.token,
          expiracion: datos.expiracion,
          datos_Usuario: datos.datos_Usuario,
        }));
        console.log("datos", datos.datos_Usuario.ROL)
        // if (datos.datos_Usuario.ROL === 'supplier') {
        //   const imageSize = window.innerWidth <= 600 ? 500 : 700;
        //   Swal.fire({
        //     imageUrl: blackfriday,
        //     imageWidth: imageSize,
        //     imageHeight: imageSize,
        //     background: 'transparent',
        //     showConfirmButton: false,
        //     showCloseButton: true,
        //     customClass: {
        //       popup: 'custom-swal-width',
        //       closeButton: 'custom-close-button',
        //     }
        //   });
        // }
        try {
          const permisos = await fetchApi({
            endPoint: "/user/obtainPermission",
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${datos.token}`,
            },
            paginacion: false,
          });

          if (permisos.error) {
            console.error(permisos.error);
            return;
          }
          recuperardatos(permisos.datos);
        } catch (error) {
          console.error("Network error:", error);
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "ERROR",
          text: "Ingresa correctamente tu usuario y contraseña",
          showConfirmButton: false,
          timer: 2500
        });
      }
    };

    try {
      const datos = await fetchApi({
        endPoint: "/user/login",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: ingreso,
        paginacion: false,
      });

      if (datos.error) {
        console.error(datos.error);
        setLoading(false);
        return;
      }
      procesamiento(datos.datos);
    } catch (error) {
      console.error("Network error:", error);
      setLoading(false);
    }
  };

  const recuperardatos = (datos) => {
    dispatch(updateAuth({
      datos_Usuario: datos.datos_Usuario,
      permissions: datos.permissions,
    }));
    if (datos.datos_Usuario.ROL === "employees") {
      navigate("/mesatrabajo");
    } else if (datos.datos_Usuario.ROL === "employeespago") {
      navigate("/mesatrabajodevoluciones");
    } else if (datos.datos_Usuario.ROL === "employeesmega") {
      navigate("/controlentrada");
    } else if (datos.datos_Usuario.ROL === "supplier") {
      navigate("/home");
    } else if (datos.datos_Usuario.ROL === "admin") {
      navigate("/registro")
    }
  };

  const handleNombre = (e) => {
    setNombre(e.target.value);
  };

  const handleContra = (e) => {
    setContra(e.target.value);
  };

  const contraseña = async () => {
    const datos = await fetchApi({
      endPoint: "/user/login",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      paginacion: false,
    });
    console.log("Si ingresa a contraseña", datos);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className='container-login'>
        {loading && <DotSpinner />}
        <div className='transparent-container'>
          <br />
          <div className="logo-container">
            <img src={logoImage} alt="Logo" className="logo" />
          </div>
          <br />
          <form className="login-form">
            <div className='InputContainer'>
              <input
                className="styled-input"
                required
                type="text"
                placeholder="Usuario"
                name="nombre"
                value={nombre}
                onChange={handleNombre}
                autoComplete="username"
              />
            </div>
            <div className='InputContainer'>
              <input
                className="styled-input"
                required
                placeholder="Contraseña"
                name="Contraseña"
                type="password"
                onChange={handleContra}
                autoComplete="current-password"
              />
            </div>
            <div className='ButtonContainer'>
              <button className="styled-button" type="submit" onClick={IngresarLogin}>Ingresar</button>
            </div>
            {/* <div>
              <a href="#" onClick={contraseña} className="recuperar_contraseña">
                ¿Olvidaste la contraseña?
              </a>
            </div> */}
          </form>
        </div>
      </div>
    </ThemeProvider>
  );
}



// const theme = createTheme();

// export default function Login() {
//   const dispatch = useDispatch();
//   useEffect(() => {
//     const imageSize = window.innerWidth <= 600 ? 500 : 700;
//     Swal.fire({
//       imageUrl: blackfriday,
//       imageWidth: imageSize,
//       imageHeight: imageSize,
//       background: "transparent",
//       showConfirmButton: false,
//       showCloseButton: false,
//       customClass: {
//         popup: "custom-swal-width",
//       },
//     });
//   }, []);

//   const IngresarLogin = async (event) => {
//     const imageSize = window.innerWidth <= 600 ? 500 : 700;
//     Swal.fire({
//       imageUrl: blackfriday,
//       imageWidth: imageSize,
//       imageHeight: imageSize,
//       background: "transparent",
//       showConfirmButton: false,
//       showCloseButton: false,
//       customClass: {
//         popup: "custom-swal-width",
//       },
//     });
//     localStorage.clear();
//     dispatch(updateAuth,updateRenovar);
//   }

//   return (
//     <ThemeProvider theme={theme}>
//       <div className="container-login">
//         <div className="transparent-container">
//           <br />
//           <div className="logo-container">
//             <img src={logoImage} alt="Logo" className="logo" />
//           </div>
//           <br />
//           <form className="login-form">
//             <div className="InputContainer">
//               <input
//                 className="styled-input"
//                 required
//                 type="text"
//                 placeholder="Usuario"
//                 autoComplete="username"
//                 readOnly
//               />
//             </div>
//             <div className="InputContainer">
//               <input
//                 className="styled-input"
//                 required
//                 placeholder="Contraseña"
//                 type="password"
//                 autoComplete="current-password"
//                 readOnly
//               />
//             </div>
//             <div className="ButtonContainer">
//               <button className="styled-button" type="button" onClick={IngresarLogin}>
//                 Ingresar
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </ThemeProvider>
//   );
// }
