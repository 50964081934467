import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import { Stack } from '@mui/material';
import Notificaciones from "../components/Notificaciones";

import Avatar from '../assets/images/avatar2.webp';
import '../css/ComponentesAdicionales/Navbar.css';

const Navbar = ({ onGroupAndRouteChange }) => {
  const [mostrar, setMostrar] = useState(false);
  const permissions = useSelector((state) => state.auth.permissions);
  const navigate = useNavigate();
  const location = useLocation();

  // Obtención de valores desde Redux
  const cardName = useSelector((state) => state.auth.datos_Usuario?.CARDNAME);
  const slpName = useSelector((state) => state.auth.datos_Usuario?.SLPNAME);
  const rol = useSelector((state) => state.auth.datos_Usuario?.ROL);

  // Condición para mostrar el nombre correcto y notificaciones
  let displayName = 'Usuario Inválido';
  let notificaciones = null;

  if (rol === 'supplier'  ) {
    displayName = cardName || 'Usuario Inválido';
    // notificaciones = <NotificacionesProveedor />;
  } else if (rol === 'employees') {
    displayName = slpName || 'Usuario Inválido';
    notificaciones = <Notificaciones />;
  } else if (rol === 'employeespago'){
    displayName = slpName || 'Usuario Inválido';
  }

  const toggleMenu = () => {
    setMostrar(!mostrar);
  };

  const modalAvatar1 = () => {
    navigate("/");
    localStorage.removeItem("token");
    localStorage.removeItem("expiracion");
    setMostrar(false);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const currentPerm = permissions.find(perm => perm.ruta === currentPath);
    const currentGroup = currentPerm ? currentPerm.grupo : 'Desconocido';
    const currentName = currentPerm ? currentPerm.nombre : 'Desconocido';

    if (onGroupAndRouteChange) {
      onGroupAndRouteChange(currentGroup, currentName);
    }
  }, [location, permissions, onGroupAndRouteChange]);

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-links desktop">
          <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} spacing={3}>
            <p >{displayName}</p>
            <div style={{ marginTop:'4px'}}>
              {notificaciones}
            </div>
            <div className="navbar-avatar" onClick={toggleMenu}>
              <img src={Avatar} alt="Logo" />
            </div>
          </Stack>
          {mostrar && (
            <div className="navbar-dropdown">
              <div className="navbar-dropdown-item" onClick={modalAvatar1}>Salir</div>
            </div>
          )}
        </div>
        <div className="navbar-menu mobile" onClick={toggleMenu}>
          &#9776;
        </div>
      </nav>
      {mostrar && (
        <div className="navbar-mobile-menu">
          {permissions.filter(item => item.permiso && item.menu).map((perm) => (
            <Link 
              key={perm.nombre} 
              to={perm.ruta} 
              className={`navbar-link-mobile ${location.pathname === perm.ruta ? 'active' : ''}`} 
              onClick={() => setMostrar(false)}
            >
              {perm.nombre}
            </Link>
          ))}
          <div className="navbar-dropdown-item-mobile" onClick={modalAvatar1}>Salir</div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
