import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Tienda from "../assets/images/mega4.png";
import LogoFinal from "../assets/images/mega.avif";
import { ReactComponent as IconHome } from "../assets/iconos/home.svg";
import { ReactComponent as IconCompras } from "../assets/iconos/carritoCompra.svg";
import { ReactComponent as IconCheckDocumento } from "../assets/iconos/checkDocumento.svg";
import { ReactComponent as IconStartDocumento } from "../assets/iconos/startDocumento.svg";
import { ReactComponent as IconMaletinCompras } from "../assets/iconos/notasCredito.svg";
import { ReactComponent as IconEtiquetasCompras } from "../assets/iconos/etiquetaPrecio.svg";
import { ReactComponent as IconDocumento } from "../assets/iconos/documento.svg";
import { ReactComponent as IconUserSecurity } from "../assets/iconos/seguridadUsuario.svg";
import { ReactComponent as IconContraseña } from "../assets/iconos/contraseña.svg";
import { ReactComponent as IconFileAdd } from "../assets/iconos/carpetaAdd.svg";
import { ReactComponent as IconStart } from "../assets/iconos/start.svg";
import { ReactComponent as IconDevoluciones } from "../assets/iconos/carritoDevoluciones.svg";
import "../css/ComponentesAdicionales/Sidebar.css";

const Sidebar = ({ onRouteChange }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const permissions = useSelector((state) => state.auth.permissions);
  const location = useLocation();

  const groupedPermissions = groupBy(
    permissions.filter((item) => item.permiso && item.menu),
    "grupo"
  );

  const handleMouseEnter = () => setIsExpanded(true);
  const handleMouseLeave = () => setIsExpanded(false);

  const handleLinkClick = (group, ruta) => {
    if (onRouteChange) {
      onRouteChange(group, ruta);
    }
  };

  return (
    <div
      className={`sidebar ${isExpanded ? "expanded" : "collapsed"}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="section">
        <div className="side-logo">
          <img src={LogoFinal} height={40} alt="Logo" />
          <span
            className={`nav-text ${isExpanded ? "visible" : "hidden"}`}
            style={{ marginLeft: "8px" }}
          >
            <img src={Tienda} height={40} alt="complemento" />
          </span>
        </div>
      </div>
      <div className="section">
        {Object.keys(groupedPermissions).length > 0 ? (
          Object.keys(groupedPermissions).map((group) => (
            <div key={group}>
              <label
                className={`section-label ${
                  isExpanded ? "expanded" : "collapsed"
                }`}
              >
                {isExpanded ? group : "-"}
              </label>
              <nav className="nav">
                <ul>
                  {groupedPermissions[group].map((perm) => (
                    <li key={perm.nombre}>
                      <Link
                        to={perm.ruta}
                        className={`nav-item ${
                          location.pathname === perm.ruta ? "active" : ""
                        }`}
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() => handleLinkClick(group, perm.nombre)}
                      >
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span
                            className={`icon ${
                              location.pathname === perm.ruta ? "active" : ""
                            }`}
                          >
                            {getIcon(perm.ruta)}
                          </span>
                          <span
                            className={`nav-text ${
                              isExpanded ? "visible" : "hidden"
                            }`}
                            style={{ marginLeft: "8px" }}
                          >
                            {perm.nombre}
                          </span>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          ))
        ) : (
          <p>No permissions available</p>
        )}
      </div>
    </div>
  );
};

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

const getIcon = (ruta) => {
  if (!ruta) {
    return null;
  }

  switch (ruta) {
    case "/home":
      return (<IconHome/>);
    case "/ordencompra":
      return (<IconCompras/>);
    case "/ordenes":
    case "/mesatrabajo":
      return (<IconCheckDocumento/>);
    case "/enprogreso":
      return (<IconStartDocumento/>);
    case "/mesatrabajodevoluciones":
    case "/pagosefectuados":
      return (<IconMaletinCompras/>);
    case "/devoluciones":
      return (<IconDevoluciones/>);
    case "/mesatrabajoprecio":
    case "/actualizacionprecio":
      return (<IconEtiquetasCompras/>);
    case "/actualizacioninformacion":
      return (<IconDocumento/>);
    case "/actualizaciondatos":
      return (<IconUserSecurity/>);
    case "/configuracion":
      return (<IconContraseña/>);
    case "/nuevoproducto":
      return (<IconFileAdd/>);
    default:
      return (<IconStart/>);
  }
};

export default Sidebar;
